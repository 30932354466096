"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const facebook_login_render_props_1 = require("react-facebook-login/dist/facebook-login-render-props");
const ui_library_1 = require("ui-library");
const styles = require("./FacebookLogin.less");
const i18n = require("i18n");
const images_1 = require("assets/images");
const FacebookLogin = ({ callback }) => (React.createElement(facebook_login_render_props_1.default, { appId: process.env.FACEBOOK_APP_ID, autoLoad: true, onClick: {}, render: renderProps => (React.createElement(ui_library_1.Button, { size: 'lg', color: 'primary', onClick: renderProps.onClick, className: `width100 margin-top-2x ${styles['button-facebook']}` },
        React.createElement("div", null,
            React.createElement("img", { src: images_1.logoFacebook, alt: 'facebook-icon' }),
            React.createElement("p", null,
                " ",
                i18n.get('LOGIN_FACEBOOK'))))), callback: response => {
        callback(response);
    } }));
exports.default = FacebookLogin;

import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import 'babel-polyfill';
import i18n from 'i18n';
import locales from 'i18n/locales';
import { initializeFirebase } from 'utils/pushNotifications';
import firebase from 'firebase';
import { get } from 'lodash';
import {Helmet} from 'react-helmet';

i18n.init({ locales });

import configureFacebookIntegration from './config/facebook';
import currentBrowserIsSupported from './config/supported-browsers';

import UnsupportedBrowser from './modules/shared/screens/UnsupportedBrowser/UnsupportedBrowser';
import tracking from './modules/legacy/js/lib/tracking/user';
import App from './AppContainer';

const FIREBASE_URL = 'firebase-messaging-sw.js';

configureFacebookIntegration(document);
if (firebase.messaging.isSupported()) initializeFirebase();

const EVENT_CODE_FOR_OLD_BROWSER = 'ANY - Old browser';

if (currentBrowserIsSupported()) renderApp();
else renderUnsupportedBrowserScreen();

if ('serviceWorker' in navigator) {
  let firebaseIsActive = false;
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      const scriptURL = get(registration, 'active.scriptURL');

      if (!scriptURL.includes(FIREBASE_URL)) {
        registration.unregister();
      } else {
        firebaseIsActive = true;
      }
    }
  });
  if (!firebaseIsActive && firebase.messaging.isSupported()) {
    navigator.serviceWorker
      .register('/firebase-messaging-sw.js')
      .then(registration => {
        firebase.messaging().useServiceWorker(registration);
        firebase.messaging().onMessage(payload => {
          if (payload.notification) {
            const notification = new Notification(
              payload.notification.title,
              payload.notification
            );
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  }
}

function renderApp() {
  render(
    <Provider store={configureStore()}>
      <Helmet>
        <meta property='fb:app_id' content={process.env.FACEBOOK_APP_ID} />
      </Helmet>
      <App />
    </Provider>,
    document.getElementById('app')
  );
}

function renderUnsupportedBrowserScreen() {
  tracking.init();
  tracking.track(EVENT_CODE_FOR_OLD_BROWSER);
  render(<UnsupportedBrowser />, document.getElementById('app'));
}

import axios from 'axios';
import { displayAlert } from 'modules/legacy/js/actions/app';
import * as i18n from 'i18n';

const loginFacebookRequest = async (token, expiresIn) => {
    try {
        const response = await axios.post('/auth/signinfb', {
            fbAuthToken: token,
            expiresIn
        });
        return response;
    } catch (e) {
        const errorMessage = e.response.data.message || i18n.get('INF_SOCIAL_ACCOUNTS_LINKING_ERROR_ALERT_TITLE');
        displayAlert({
            type: 'error',
            tittle: 'Error',
            message: errorMessage
        });
    }
};

export default loginFacebookRequest;
